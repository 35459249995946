import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['sessionSelect']

  connect() {
  }

  // Este método se llama cuando el select del evento cambia
  loadSessions(event) {
    const eventId = event.target.value;

    if (eventId) {
      fetch(`/admin_panel/events/${eventId}/sessions`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
          return response.json();
        })
        .then(data => {
          const sessions = data.sessions;
          this.updateSessions(sessions);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      this.clearSessions();
    }
  }

  updateSessions(sessions) {
    const select = this.sessionSelectTarget;
    select.innerHTML = '';

    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.text = 'Sin asignar';
    select.appendChild(defaultOption);

    sessions.forEach(session => {
      const option = document.createElement('option');
      option.value = session.id;
      option.text = session.start_time;
      select.appendChild(option);
    });
  }

  clearSessions() {
    this.sessionSelectTarget.innerHTML = '<option value="">Sin asignar</option>';
  }
}
